<template>
  <div :class="$style.give">
    <div>
      <Back />
      <div :class="$style.content">
        <p :class="$style.title" v-if="status === 1">转赠确认</p>
        <div v-if="status === 2">
          <p :class="$style.title">转赠中</p>
          <p :class="$style.tip">{{ expireTime }} 前对方未领取，将退还给您</p>
        </div>
        <div :class="$style.card">
          <div :class="$style.infoWrap">
            <img
              :class="$style.cover"
              :src="info.ddc_image_url || info.image_url"
            />
            <div :class="$style.info">
              <div>
                <p :class="$style.name">{{ info.ddc_name || info.name }}</p>
                <div :class="$style.author">
                  <img :class="$style.avatar" :src="info.headimg" />
                  <span>{{ info.artist_name }}</span>
                </div>
              </div>
              <p :class="$style.time">
                {{
                  status === 1
                    ? `拥有日期：${info.created_at}`
                    : `当前拥有者：${owner}`
                }}
              </p>
            </div>
          </div>
          <div v-if="status === 1" :class="$style.giveInfo">
            <div :class="$style.line">
              <span>接受人手机号/账号/ID：</span>
              <input
                type="text"
                v-model="mobile"
                :class="$style.input"
                @change="handleInput"
              />
            </div>
            <div :class="$style.line">
              <span>接受人昵称：</span>
              <span>{{ nickname }}</span>
            </div>
          </div>
          <div v-if="status === 2" :class="$style.giveInfo">
            <div :class="$style.line">
              <span>赠送内容：</span>
              <span>{{ info.ddc_name || info.name }}</span>
            </div>
            <div :class="$style.line">
              <span>转赠时间：</span>
              <span>{{ giveInfo.created_at }}</span>
            </div>
            <div :class="$style.line">
              <span>订单号：</span>
              <span>{{ giveInfo.transfer_no }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="$style.bottom" v-if="status === 1">
      <div :class="$style.btn" @click="handleConfirm">确认转赠</div>
    </div>
    <div :class="[$style.bottom, $style.fixed]" v-if="status === 2">
      <div :class="[$style.btn, $style.small]" @click="handleSuccess">完成</div>
      <div :class="[$style.btn, $style.small]" @click="handleShowPoster">
        通知好友
      </div>
    </div>
    <van-popup v-model="showPosterPopup" :class="$style.posterPopup">
      <img v-if="showPoster" :src="canvasUrl" :class="$style.canvas" />
      <div v-else :class="$style.poster" ref="poster">
        <p :class="$style.title">已赠送：{{ nickname }}</p>
        <img
          :class="$style.cover"
          :src="info.ddc_image_url || info.image_url"
        />
        <p :class="$style.name">{{ info.ddc_name || info.name }}</p>
        <p>艺术家：{{ info.artist_name }}</p>
        <div :class="$style.line"></div>
        <div :class="$style.qrcodeWrap">
          <div ref="qrcode" :class="$style.qrcode"></div>
        </div>
        <p :class="$style.ownerWrap">
          <span :class="$style.owner">拥有者：{{ owner }}</span>
          <img :class="$style.logo" src="@/assets/img/logo.png" />
        </p>
      </div>
      <p :class="$style.posterTip">长按保存图片或分享给好友</p>
    </van-popup>
  </div>
</template>

<script>
import Back from "@/components/Back";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import { fetchUser, transferDdc } from "@/api";
import { getStorage } from "@/utils/util";

export default {
  components: {
    Back,
  },
  data() {
    return {
      status: 1, // 1:确认转赠，2转赠中
      mobile: "",
      info: JSON.parse(this.$route.query.info),
      path: this.$route.query.path, // spin国家纺织中心作品详情，cloth艺术家作品详情
      nickname: "",
      owner: getStorage("user_info").nickname,
      giveInfo: {},
      showPosterPopup: false,
      canvasUrl: "",
      showPoster: false,
    };
  },
  computed: {
    expireTime() {
      let dateTime = new Date(
        this.giveInfo.created_at && this.giveInfo.created_at.replace(/-/g, "/")
      );
      dateTime = dateTime.setDate(dateTime.getDate() + 1);
      dateTime = new Date(dateTime);
      const expireTime = dateTime.toLocaleString();
      return expireTime;
    },
  },
  methods: {
    handleConfirm() {
      if (!this.nickname) {
        this.$toast("请输入正确的接受人手机号/账号/ID");
        return;
      }
      const data = {
        ddc_id: this.info.ddc_id,
        mobile: this.mobile,
      };
      transferDdc(data).then((res) => {
        this.giveInfo = res;
        this.status = 2;
      });
    },
    handleInput() {
      const data = {
        mobile: this.mobile,
      };
      fetchUser(data)
        .then((res) => {
          this.nickname = res.nickname;
        })
        .catch(() => {
          this.nickname = "";
        });
    },
    handleSuccess() {
      this.$router.push("/give");
    },
    handleShowPoster() {
      this.showPosterPopup = true;
      this.$nextTick(() => {
        this.makeQrcode();
      });
    },
    makeQrcode() {
      const urlMap = {
        spin: `/spin/detail/${this.info.ddc_id}`,
        cloth: `/cloth/detail/${this.info.ddc_id}`,
      };
      const url = location.protocol + "//" + location.host + urlMap[this.path];
      new QRCode(this.$refs.qrcode, {
        text: url, // 扫码后页面地址
        width: 68, // 二维码宽度
        height: 68, // 二维码高度
        colorDark: "#A1D8F8", // 二维码颜色
        colorLight: "#212121", // 背景颜色
        correctLevel: QRCode.CorrectLevel.H, // 校正水准
      });
      this.createPoster();
    },
    createPoster() {
      new html2canvas(this.$refs.poster, {
        allowTaint: true,
        useCORS: true,
        backgroundColor: "#212121",
      }).then((canvas) => {
        this.canvasUrl = canvas.toDataURL();
        this.showPoster = true;
      });
    },
  },
};
</script>

<style lang="scss" module>
.give {
  min-height: 100vh;
  padding-bottom: 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content {
  padding-top: 8px;
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
  }
  .tip {
    font-weight: 500;
    line-height: 20px;
    margin-top: 8px;
    text-align: center;
  }
  .card {
    margin: 20px auto 0;
    width: 335px;
    padding: 8px;
    box-sizing: border-box;
    background: #212121;
    border-radius: 14px;
    .infoWrap {
      display: flex;
      .cover {
        width: 99px;
        height: 99px;
        border-radius: 10px;
        margin-right: 9px;
      }
      .info {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .name {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          height: 40px;
          @include ellipsis(2);
        }
        .author {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          color: #b9b9b9;
          margin-top: 7px;
          .avatar {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
        .time {
          font-size: 12px;
          color: #b9b9b9;
          line-height: 17px;
        }
      }
    }
    .giveInfo {
      margin-top: 50px;
      font-weight: 500;
      line-height: 20px;
      .line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        padding: 0 10px 0 5px;
        .input {
          height: 24px;
          line-height: 24px;
          width: 110px;
          padding-left: 10px;
          border: none;
          color: #212121;
        }
      }
    }
  }
}
.bottom {
  width: 335px;
  margin: 100px auto 0;
  display: flex;
  justify-content: space-between;
  &.fixed {
    position: fixed;
    bottom: 36px;
    left: 20px;
  }
  .btn {
    background: #212121;
    border-radius: 28px;
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    &.small {
      width: 162px;
    }
  }
}
.posterPopup {
  background-color: #212121;
  border-radius: 12px;
  .posterTip {
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #e9b0ef;
    background: linear-gradient(90deg, #e9b0ef 50%, #5ffcff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
  }
  .canvas {
    width: 318px;
  }
  .poster {
    width: 318px;
    box-sizing: border-box;
    padding: 16px 27px;
    background: #212121;
    border-radius: 12px;
    color: #ffffff;
    font-weight: 500;
    line-height: 24px;
    font-size: 12px;
    .title {
      height: 22px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
    }
    .cover {
      width: 264px;
      height: 264px;
      margin: 17px 0 22px;
    }
    .name {
      font-size: 20px;
      margin-bottom: 5px;
    }
    .line {
      border-bottom: 2px dashed #3e3e3e;
      margin: 17px 0 23px;
    }
    .qrcodeWrap {
      width: 81px;
      height: 81px;
      width: 81px;
      height: 81px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: url("~@/assets/img/qrcode_bg.png");
      background-size: 100% 100%;
      .qrcode {
        width: 68px;
        height: 68px;
        img {
          width: 68px;
          height: 68px;
        }
        canvas {
          width: 68px !important;
          height: 68px !important;
        }
      }
    }
    .ownerWrap {
      height: 30px;
      display: flex;
      justify-content: space-between;
      .owner {
        align-self: flex-end;
        height: 24px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
      .logo {
        align-self: flex-start;
        width: 65px;
        height: 23px;
      }
    }
  }
}
</style>
